import React from 'react'
import { Router } from '@reach/router'
import Build from '../../components/Make/Views/Build'
import DraftPreview from '../../components/SlideshowDraftPreview'
import DraftTest from '../../components/SlideshowDraftTest'
import Profile from '../../components/User/Profile'
import Login from '../../components/User/Login'
import PrivateRoute from '../../components/PrivateRoute'

import { AuthProvider } from '../../components/User/AuthProvider'

const App = () :JSX.Element => (
	<AuthProvider enableAnonymouseAccountCreation>
		<Router basepath="/make">
			{/* TODO Deprecate these after Nurislam updates to the new urls */}

			{/* Beginning of deprecation ------------- */}
			<DraftPreview path="/preview/:id" />
			<DraftTest path="/draft/:id" />
			<DraftTest path="/draft/:id/id/:startAtId" />
			{/* ---- end of deprecation */}

			<DraftPreview path="/draft/preview/:id" />
			<DraftTest path="/draft/test/:id" />
			<DraftTest path="/draft/test/:id/id/:startAtId" />
			<PrivateRoute path="/profile" component={Profile} />
			<PrivateRoute path="/build" component={Build} />
			<Login path="/login" />
		</Router>
	</AuthProvider>
)

export default App

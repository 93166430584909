import React, { useState, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { SlideshowTypeKeyed } from '../common/shared/schema12'
import SlideShow from './slideshows/Schema12'
import Wrapper from './slideshows/Wrapper'
import LoaderOverlay from './slideshows/LoadingOverlay'

type SlideShowLoadStatus = 'loading' | 'failed' | 'success' | 'pending'

const SlideshowDraftPreview = ({ id }:{id:string}) :JSX.Element => {
	const [slideShowLoadStatus, setSlideShowLoadStatus] = useState<SlideShowLoadStatus>('pending')
	const [slideshow, setSlideshow] = useState<SlideshowTypeKeyed>()

	useEffect(() => {
		function fetch():void {
			try {
				setSlideShowLoadStatus('loading')
				firebase
					.firestore()
				// .collection('slideshows')
					.collection('drafts')
					.doc(id)
					.get()
					.then((doc) => {
						if (doc.exists) {
							// eslint-disable-next-line no-console
							// console.log('Document data:', doc.data())
							// TODO: add branching support for various "schemaX"
							setSlideshow({
								...doc.data(),
								id,
							} as SlideshowTypeKeyed)
							setSlideShowLoadStatus('success')
						} else {
							//  TODO
							// doc.data() will be undefined in this case
							// setSlideshow(null)
							// eslint-disable-next-line no-console
							// console.log('No such document!')
							setSlideShowLoadStatus('failed')
						}
					})
			} catch (error) {
				// console.log('Error getting stats:', error)
				setSlideShowLoadStatus('failed')
			}
		}

		if (slideShowLoadStatus === 'pending') {
			fetch()
		}
	}, [id, slideShowLoadStatus])

	let jsx: JSX.Element = <></>

	if (slideShowLoadStatus === 'failed') {
		jsx = <div>Yikes! No preview available</div>
	} else if (slideShowLoadStatus === 'success') {
		jsx = <Wrapper slideshow={slideshow}><SlideShow displayMode="draft:preview" /></Wrapper>
	} else if (slideShowLoadStatus === 'loading' || slideShowLoadStatus === 'pending') {
		jsx = <LoaderOverlay />
	}

	return jsx
}

export default SlideshowDraftPreview

import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../Header'
import * as Styles from './styles'

const Layout = ({ children }:{children:any}):JSX.Element => (
	<Styles.Container>
		<Helmet title="Gatsby Simple Firebase Authentication" />
		<Header />
		<main>{children}</main>
	</Styles.Container>
)
//
export default Layout

import React from 'react'
import Layout from '../Layout/make'

const View = ():JSX.Element => (
	<Layout>
		<>
			<div>this is the build view.  this is a test</div>
			{/* <MySlideShows /> */}
		</>
	</Layout>
)

export default View

import styled from 'styled-components'
// import { device, desktopDemoDevice } from '../../../utils/device'

export const Container = styled.div`
	width: 100%;
  height: 100%;
  display: inline-flex;
  background-color: #ffffff
`

export const Spinner = styled.div`
  margin: auto;
  width: 200px;
  height: 200px;
  align-self: center;
`

export const SpinnerImage = styled.img`
 object-fit: contain;
 width: 100%;
 height: 100%;
`

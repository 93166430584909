import React, { useContext } from 'react'
import { Link, navigate } from '@reach/router'
import firebase from 'gatsby-plugin-firebase'
import { AuthContext, AuthContextProps } from './AuthProvider'

export default ():JSX.Element => {
	let details
	const { authenticated, user, Logout }:Partial<AuthContextProps> = useContext(AuthContext)

	if (!authenticated) {
		details = (
			<p className="text-right px-5">
				<Link to="/make/login"><u>Log in</u></Link>
			</p>
		)
	} else {
		const { displayName, email } = user as firebase.User
		details = (
			<p className="text-right px-5">
				Logged in as
				{' '}
				{displayName}
				{' '}
				(
				{email}
				)!
				{' '}
				<a href="/" onClick={(event) => { event.preventDefault(); (Logout as Function)().then(() => navigate('/make/login')) }}>
					<u>log out</u>
				</a>
			</p>
		)
	}

	return <div>{details}</div>
}

import React, { useContext } from 'react'

import { navigate } from 'gatsby'

import { AuthContext, AuthContextProps } from './User/AuthProvider'

const PrivateRoute = ({ component: Component, location, ...rest }:{component:any; location:any}):JSX.Element => {
	const { authenticated }:Partial<AuthContextProps> = useContext(AuthContext)
	if (!authenticated && location.pathname !== '/make/login') {
		// If we’re not logged in, redirect to the home page.
		navigate('/make/login', { replace: true })
		// return null
		return <></>
	}

	return <Component {...rest} />
}

// PrivateRoute.propTypes = {
//   component: PropTypes.any.isRequired,
// }

export default PrivateRoute

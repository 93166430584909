import styled from 'styled-components'

export const NavBar = styled.div`
	margin: 0 auto;
	width: 100%;
  max-width: 960px;
	padding: 1.45rem 1.0875rem;
	background-color: #fff000;
	height: 80px;
`

export const Header = styled.header`
	margin: 0 auto;
	width: 100%;
  max-width: 960px;
	padding: 1.45rem 1.0875rem;
	background-color: #fff000;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	min-height: 72px;
`
export const NavBarMenuOptionContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	border:1px solid red;
`

export const NavBarMenuUL = styled.ul`
	
`

export const NavBarMenuLI = styled.ul`
	
`

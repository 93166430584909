
import React from 'react'
import * as Styles from './styles'
import loaderGif from '../../../images/omg.gif'
import Layout from '../../../layouts/smartLayout'

const LoadingOverlay = () :JSX.Element => (
	<Layout>
		<Styles.Container>
			<Styles.Spinner>
				<Styles.SpinnerImage src={loaderGif} alt="animated gif" />
			</Styles.Spinner>
		</Styles.Container>
	</Layout>
)

export default LoadingOverlay

import React from 'react'
import { Link } from 'gatsby'
import * as Styles from './styles'

const Header = ():JSX.Element => (
	<Styles.Header>
		<Styles.NavBarMenuOptionContainer>
			<Styles.NavBarMenuUL>
				<Styles.NavBarMenuLI>
					<Link to="/make/profile">
						Profile
					</Link>
				</Styles.NavBarMenuLI>
				<Styles.NavBarMenuLI>
					<Link to="/make/:id/edit">
						Create
					</Link>
				</Styles.NavBarMenuLI>
				<Styles.NavBarMenuLI>
					<Link to="/make/build">
						Build
					</Link>
				</Styles.NavBarMenuLI>
			</Styles.NavBarMenuUL>
		</Styles.NavBarMenuOptionContainer>
	</Styles.Header>
)

export default Header

import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'gatsby-plugin-firebase'
import View from './Layout'
import { AuthContext, AuthContextProps } from './AuthProvider'

// Configure FirebaseUI.
const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase?.auth?.GoogleAuthProvider.PROVIDER_ID,
	],
	callbacks: {
		// Avoid redirects after sign-in.
		signInSuccessWithAuthResult: () => false,
	},
}

const SignInScreen = ():JSX.Element => {
	// const [isSignedIn] = useFirebaseForMake()
	const { authenticated }:Partial<AuthContextProps> = useContext(AuthContext)
	if (!authenticated) {
		return (
			<View title="Log In">
				<h1>My App</h1>
				<p>Please sign-in:</p>
				<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase?.auth()} />
			</View>
		)
	}

	navigate('/make/profile')

	return <></>
	// return (
	//   <div>
	//     <h1>My App</h1>
	//     <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
	//     <a onClick={() => (Logout as Function)()}>Sign-out</a>
	//   </div>
	// );
}

export default SignInScreen
